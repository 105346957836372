import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as BrandLogo } from "../assets/images/logo.svg";
import { notify } from "../reducers/treeMapSlice";
import { resetPassword } from "../services/api-service";
import { IsMobile } from "../utils/deviceType";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: null,
      confPassword: null,
    },
  });
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isMobile = IsMobile();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const { password, confPassword } = data;
    if (password && confPassword) {
      resetPassword({ password, comfirm_password: confPassword }).then(
        (res) => {
          if (res.status === 200) {
            notify("Password reset successfully.", "success");
            setErrorMessage(undefined);
            reset({ password: null, confPassword: null });
            navigate("/signin");
          } else setErrorMessage("Unable to reset password.");
        }
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: isMobile ? "8%" : "4rem",
      }}
    >
      <BrandLogo />
      <Typography
        sx={{
          color: "#2d333a",
          margin: `${isMobile ? "20%" : "120px"}  0px 24px`,
          fontFamily: "ClashDisplay-Medium",
          fontSize: 30,
        }}
      >
        Reset your passowrd
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "320px",
          maxWidth: "80vw",
        }}
      >
        <Controller
          control={control}
          name="password"
          rules={{
            required: "Enter a password",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
          }}
          render={({ field }) => (
            <TextField
              label="Password"
              {...field}
              helperText={errors?.password?.message}
              error={Boolean(errors.password)}
              sx={{
                marginTop: "12px",
                "&:hover fieldset": {
                  border: "1px solid rgba(0, 0, 0, 0.23) !important",
                  borderLeft: "3px solid #1D77B7 !important",
                },
                "& .Mui-focused fieldset": {
                  border: "1px solid #1D77B7 !important",
                },
                "& input": {
                  height: "1.5em",
                  padding: "14px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      style={{ paddingRight: "10px" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
            />
          )}
        />
        <Controller
          control={control}
          name="confPassword"
          rules={{
            required: "Enter a confirm password",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
            validate: {
              matchPassword: (value) =>
                value === getValues("password") || "Passwords do not match",
            },
          }}
          render={({ field }) => (
            <TextField
              label="Confirm Password"
              {...field}
              helperText={errors?.confPassword?.message}
              error={Boolean(errors.confPassword)}
              sx={{
                marginTop: "12px",
                "&:hover fieldset": {
                  border: "1px solid rgba(0, 0, 0, 0.23) !important",
                  borderLeft: "3px solid #1D77B7 !important",
                },
                "& .Mui-focused fieldset": {
                  border: "1px solid #1D77B7 !important",
                },
                "& input": {
                  height: "1.5em",
                  padding: "14px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                      style={{ paddingRight: "10px" }}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showConfirmPassword ? "text" : "password"}
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "26px",
            mb: 0,
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: 45,
              borderRadius: "6px",
              fontSize: "16px",
              fontFamily: "sans-serif",
              textTransform: "none",
              width: "100%",
              backgroundColor: "#1D77B7",
              boxShadow: "none",
              "&:hover": {
                background: "#0f639e",
                boxShadow: "none",
              },
            }}
          >
            Reset Password
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            textAlign: "center",
            color: "var(--text-primary)",
          }}
        >
          <Typography
            sx={{
              visibility: errorMessage ? "visible" : "hidden",
            }}
            color="error"
          >
            {errorMessage}
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default ResetPassword;
