import { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getUserData, signOut } from "../services/api-service";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../reducers/treeMapSlice";

const AuthContext = createContext();
const apiUrl = `${process.env.REACT_APP_API_BASE_URL_DEV}/v1`;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const userData = useSelector((state) => state.treeMap.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (res) => {
    dispatch(setUserData(res?.user));
    setUser(res?.user)
    if (res?.user?.judo_Application?.subscriptions.length === 0 ||
      (res?.user?.judo_Application?.subscriptions.length && !res.user.judo_Application.subscriptions[0].active)) {
      navigate("/subscribe")
    }
    else navigate(res?.user?.judo_Application?.createdEvent ? "/" : "/create-event")
  }

  const signUp = async ({ email, password }) => {
    try {
      setAuthLoading(true);
      const response = await axios.post(`${apiUrl}/auth/signup`, { email, password }, {
        headers: {
          "x-application": "judo"
        }
      })
      if (response?.status === 200) {
        setUser(response.data.user);
        setToken(response.data.token);
        localStorage.setItem("accessToken", response.data.token);
        navigate("/subscribe")
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
      return err;
    }
    finally {
      setTimeout(() => {
        setAuthLoading(false)
      }, 100);
    }
  };

  const signIn = async ({ email, password }) => {
    try {
      setAuthLoading(true);
      const res = await axios.post(`${apiUrl}/auth/signin`, { email, password }, {
        headers: {
          "x-application": "judo"
        }
      })
      if (res.data) {
        setToken(res.data.token);
        localStorage.setItem("accessToken", res.data.token);
        handleNavigation(res.data);
        return
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
      return err;
    }
    finally {
      setTimeout(() => {
        setAuthLoading(false)
      }, 100);
    }
  };

  const signInWithGoogle = async (accessToken) => {
    try {
      setAuthLoading(true);
      const res = await axios.post(`${apiUrl}/auth/google-judo`, { idToken: accessToken })
      if (res.data) {
        setToken(res.data.token);
        localStorage.setItem("accessToken", res.data.token)
        handleNavigation(res.data);
        return
      }
      throw new Error(res.message);
    } catch (err) {
      console.error("Google Sign-In Error:", err);
      return err;
    }
    finally {
      setTimeout(() => {
        setAuthLoading(false)
      }, 100);
    }
  };

  useEffect(() => {
    if (!authLoading) {
      if (token) {
        getUserData(token).then((res) => {
          if (res?.user !== userData) {
            dispatch(setUserData(res?.user));
            setUser(res?.user)
          }
        })
      }
      else signOut()
    }
  }, [token])

  return (
    <GoogleOAuthProvider clientId="842490433385-qv8flf7pikmt7mm4ndrj2rn1emohqu2t.apps.googleusercontent.com">
      <AuthContext.Provider value={{ token, authLoading, setAuthLoading, user, signUp, signIn, signInWithGoogle }}>
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};