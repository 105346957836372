import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddchartIcon from "@mui/icons-material/Addchart";
import DecisionTreeVisualization from "../DecisionTreeVisualization/DecisionTreeVisualization";
import Loader from "../Loader/Loader";
import IndentedList from "../IndentedList/IndentedList";
import { useNavigate } from "react-router-dom";
import KPIs from "../KPIs/KPIs";

export default function Layout({
  setSidebarOpened,
  setShowManageEvent,
  isKPIView,
}) {
  const navigate = useNavigate();
  const treeHistoryLoading = useSelector(
    (state) => state.treeMap.isTreeHistoryLoading
  );
  const eventData = useSelector((state) => state.treeMap.eventData);
  const eventFullData = useSelector((state) => state.treeMap.eventFullData);
  const eventHistory = useSelector((state) => state.treeMap.history);
  const loading = useSelector((state) => state.treeMap.loading);
  const visualizationType = useSelector(
    (state) => state.treeMap.visualizationType
  );
  const eventGenerating = useSelector((state) => state.treeMap.eventGenerating);
  const planGenerating = useSelector((state) => state.treeMap.planGenerating);
  const eventRatings = useSelector((state) => state.treeMap.eventRatings);
  const selectedTreePlannedNodeNames = useSelector(
    (state) => state.treeMap.selectedTreePlannedNodeNames
  );
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const userData = useSelector((state) => state.treeMap.userData);
  const shareLinks = useSelector((state) => state.treeMap.shareLinks);

  const dispatch = useDispatch();

  return (
    <Box style={{ height: "100%", position: "relative" }}>
      {isKPIView ? (
        <KPIs />
      ) : (treeHistoryLoading && !eventFullData?._id) || loading ? (
        <Loader text={"Loading..."} />
      ) : eventData ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          {visualizationType === 2 ? (
            <DecisionTreeVisualization
              treeMap={
                eventData?.Pitfalls
                  ? {
                      name: eventHistory?.find(
                        (el) => el._id === eventData?._id
                      )?.job_title,
                      children: [...eventData.Pitfalls],
                    }
                  : eventData
              }
            />
          ) : (
            <IndentedList
              userData={userData}
              eventData={eventFullData}
              shareLinks={shareLinks}
              selectedTreeNode={selectedTreeNode}
              selectedTreePlannedNodeNames={selectedTreePlannedNodeNames}
              eventRatings={eventRatings}
              eventGenerating={eventGenerating}
              data={eventData?.Pitfalls ?? eventData?.children}
              dispatch={dispatch}
              planGenerating={planGenerating}
              setSidebarOpened={setSidebarOpened}
              setShowManageEvent={setShowManageEvent}
            />
          )}
        </Box>
      ) : (
        <Box className="create-chart-button-container">
          <Tooltip title="Generate new Event">
            <IconButton
              onClick={() => navigate("/create-event")}
              color="primary"
            >
              <AddchartIcon
                className="add-chart-icon"
                fontSize="large"
                color="primary"
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
