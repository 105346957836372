import React, { useEffect, useState } from 'react';


export const IsMobile = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < window.innerHeight)

    const isMobileFn = () => setIsMobile(window.innerWidth < window.innerHeight);

    useEffect(() => {
        isMobileFn()
        window.addEventListener("resize", isMobileFn);
        return () => window.removeEventListener("resize", isMobileFn);
    }, [])

    return isMobile;
};
