import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/images/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteIcon.svg";
import Loader from "../Loader/Loader";
import {
  createKPIs,
  deleteSingleKPI,
  editSingleKPI,
} from "../../reducers/treeMapSlice";
import { useSelector, useDispatch } from "react-redux";
import TextEntryModal from "../TextEntryModal/TextEntryModal";
import "./KPIs.css";

const KPIs = () => {
  const dispatch = useDispatch();
  const [selectedKPI, setSelectedKPI] = useState(null);
  const [editKPIParams, setEditKPIParams] = useState(null);
  const KPIs = useSelector((state) => state.treeMap.KPIs);
  const eventId = useSelector((state) => state.treeMap.selectedTreeId);
  const loading = useSelector((state) => state.treeMap.loading);
  const KPIsGenerating = useSelector((state) => state.treeMap.KPIsGenerating);

  const KPIsTable = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          width: "100%",
          border: "1px solid #E0E0E0",
        }}
      >
        <Box className="header-row">
          {["KPI", "Target", "Current", "Status"].map((header) => (
            <Box key={header} className="header-cell">{header}</Box>
          ))}
          <Box className="header-cell" />
        </Box>
        {KPIs.kpisData.map((kpi) => (
          <Box
            className="kpi-row"
            key={kpi._id}
            sx={{
              marginBottom: "10px",
              backgroundColor:
                selectedKPI?._id === kpi._id ? "#f8fafb" : "white",
            }}
            onClick={() => setSelectedKPI(kpi)}
          >
            {["name", "target", "current"].map((field) => (
              <Box
                key={field}
                className="kpi-cell"
                sx={{
                  color: !kpi[field] && "#007bff",
                }}
                onDoubleClick={(e) => {
                  e.stopPropagation();
                  e.target.setAttribute("contenteditable", "true");
                  e.target.focus();
                }}
                onBlur={(e) => {
                  dispatch(
                    editSingleKPI({
                      eventId: eventId,
                      kpiId: kpi._id,
                      [field]: e.target.innerText,
                    })
                  );
                }}
              >
                {kpi[field] || "Double Click to Edit"}
              </Box>
            ))}
            <Box className="kpi-cell">
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    editSingleKPI({
                      eventId: eventId,
                      kpiId: kpi._id,
                      status: !kpi.status,
                    })
                  );
                }}
                className="status-box"
                sx={{
                  border: `1px solid ${kpi.status ? "#2EA674" : "#E0A714"}`,
                  color: kpi.status ? "#2EA674" : "#E0A714",
                }}
              >
                {kpi.status ? "On" : "Off"} Track
              </Box>
            </Box>
            <Box className="kpi-cell">
              <DeleteIcon
                className="delete-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(deleteSingleKPI(eventId, kpi._id));
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const ExtraInfo = () => {
    return (
      <Box className="extraInfo">
        <Box className="extraInfoBox">
          <Box className="extraInfoBoxHeader">
            Description
            <EditIcon
              cursor="pointer"
              onClick={() => setEditKPIParams("description")}
            />
          </Box>
          <Box sx={{ fontSize: "14px", color: "#808080" }}>
            {selectedKPI?.description}
          </Box>
        </Box>
        <Box className="extraInfoBox">
          <Box className="extraInfoBoxHeader">
            Calculation Method
            <EditIcon
              cursor="pointer"
              onClick={() => setEditKPIParams("formula")}
            />
          </Box>
          <Box sx={{ fontSize: "14px", color: "#808080" }}>
            {selectedKPI?.formula}
          </Box>
        </Box>
        <Box className="extraInfoBox">
          <Box className="extraInfoBoxHeader">
            Pitfall
            <EditIcon
              cursor="pointer"
              onClick={() => setEditKPIParams("pitfall")}
            />
          </Box>
          <Box sx={{ fontSize: "14px", color: "#808080" }}>
            {selectedKPI?.pitfall}
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (selectedKPI) {
      const newKPI = KPIs?.kpisData.find((kpi) => kpi._id === selectedKPI._id);
      setSelectedKPI(newKPI);
    }
  }, [KPIs]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "15px",
        boxSizing: "border-box",
      }}
    >
      {loading || KPIsGenerating ? (
        <Loader
          text={KPIsGenerating ? "Generating KPIs..." : "Loading KPIs..."}
        />
      ) : KPIs ? (
        <>
          <KPIsTable />
          {selectedKPI && <ExtraInfo />}
        </>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            mt: "5%",
            fontSize: "1rem",
            fontFamily: "ClashDisplay-Medium",
          }}
        >
          No KPIs found,{" "}
          <Box
            component="span"
            onClick={() => dispatch(createKPIs(eventId))}
            sx={{ cursor: "pointer", color: "#007FFF" }}
          >
            Create some{" "}
          </Box>
          to get started
        </Box>
      )}
      {editKPIParams && (
        <TextEntryModal
          header={`Edit KPI ${editKPIParams}`}
          initialValue={selectedKPI[editKPIParams]}
          buttonText="Edit KPI"
          textEntryModalOpened={editKPIParams}
          setTextEntryModalOpened={setEditKPIParams}
          action={(value) =>
            dispatch(
              editSingleKPI({
                eventId: eventId,
                kpiId: selectedKPI._id,
                [editKPIParams]: value,
              })
            )
          }
        />
      )}
    </Box>
  );
};

export default KPIs;
