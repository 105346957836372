import React, { useEffect, useState } from "react";
import BrandLogo from "./assets/images/logo.svg";

const ErrorBoundary = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return !isOnline ? (
    <div className="error-boundary">
      <img
        src={BrandLogo}
        alt="Logo"
        style={{
          maxWidth: "300px",
        }}
      />
      <p>Network error: Please check your internet connection.</p>
    </div>
  ) : (
    children
  );
};

export default ErrorBoundary;
