import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { ReactComponent as Note } from "../../assets/images/icons/note.svg";
import { ReactComponent as Tree } from "../../assets/images/icons/tree.svg";
import { ReactComponent as Correct } from "../../assets/images/icons/correct.svg";
import { ReactComponent as Rocket } from "../../assets/images/icons/rocket.svg";
import { useSelector } from "react-redux";

export const Steps = ({
  currentStepNumber,
  setTreeInfoChanged,
  setCurrentStepNumber,
}) => {
  const lastCompletedStep = useSelector(
    (state) => state.treeMap.lastCompletedStep
  );
  const steps = [
    {
      title: "Context",
      icon: (color) => <Note stroke={color} width="60%" />,
      description:
        "Provide key details about your role, project, and objectives. This context enables our AI to generate tailored, high-value outputs.",
    },
    {
      title: "Inversion Tree",
      icon: (color) => <Tree fill={color} width="60%" />,
      description:
        "Review the generated risk factors. Remove irrelevant items with 🗑️ and highlight critical concerns with ⚠️ to refine your strategic focus and KPIs.",
    },
    {
      title: "KPIs",
      icon: (color) => <Rocket fill={color} width="60%" />,
      description:
        "Evaluate the suggested performance indicators. Select those most aligned with your goals to effectively track progress and mitigate risks.",
    },
  ];

  const StepIcon = ({
    isCurrentStep,
    canActivateStep,
    isStepCompleted,
    index,
  }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexShrink: 0,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "100%",
          border: `1px solid ${isStepCompleted || isCurrentStep ? "#1D77B7" : canActivateStep ? "black" : "gray"}`,
          width: 30,
          height: 30,
          marginLeft: "-5px",
          backgroundColor:
            isStepCompleted || isCurrentStep ? "#1D77B7" : "white",
        }}
      >
        {isStepCompleted ? (
          <Correct width="50%" />
        ) : (
          steps[index].icon(
            isStepCompleted || isCurrentStep
              ? "white"
              : canActivateStep
                ? "black"
                : "gray"
          )
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Stepper activeStep={currentStepNumber - 1} orientation="vertical">
        {steps.map((step, index) => {
          const isCurrentStep = currentStepNumber === index + 1;
          const canActivateStep = lastCompletedStep + 1 >= index + 1;
          const isStepCompleted = lastCompletedStep >= index + 1;
          return (
            <Step
              key={step.title}
              sx={{
                cursor: canActivateStep ? "pointer" : "default",
                position: "relative",
              }}
              onClick={() => (
                canActivateStep && setCurrentStepNumber(index + 1),
                setTreeInfoChanged(false)
              )}
              disabled={!canActivateStep}
            >
              <Box
                color="gray"
                fontSize="12px"
                position="absolute"
                ml="35px"
                mt="-3px"
              >
                Step {index + 1}
              </Box>
              <StepLabel
                StepIconComponent={() =>
                  StepIcon({
                    isCurrentStep,
                    canActivateStep,
                    isStepCompleted,
                    index,
                  })
                }
                sx={{
                  ".MuiStepLabel-label": {
                    color: canActivateStep ? "black" : "gray",
                    fontFamily: isCurrentStep
                      ? "ClashDisplay-Medium"
                      : "ClashDisplay-Regular",
                    fontSize: "16px",
                  },
                }}
              >
                {step.title}
              </StepLabel>
              <StepContent>
                <Typography
                  color="gray"
                  fontFamily="ClashDisplay-Regular"
                  fontSize="14px"
                >
                  {step.description}
                </Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
