import { Box, Button } from "@mui/material";
import { useState, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import "../assets/styles/Dashboard.css";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog/DeleteConfirmationDialog";
import Plans from "../components/Plans/Plans";
import { IsMobile } from "../utils/deviceType";
// import OnBoardingFlow from "../components/OnBoardingFlow/OnBoardingFlow";
import ManageEvent from "../components/ManageEvent/ManageEvent";
import SideBar from "../components/SideBar/SideBar";

export default function Dashboard() {
  // const visibleRightPanel = useSelector(
  //   (state) => state.treeMap.visibleRightPanel
  // );
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const sidebarRef = useRef(null);
  // const [isResizing, setIsResizing] = useState(false);
  const [showManageEvent, setShowManageEvent] = useState(false);
  // const [sidebarWidth, setSidebarWidth] = useState("20%");
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [isKPIView, setIsKPIView] = useState(false);
  const isMobile = IsMobile();

  // const startResizing = useCallback(() => {
  //   setIsResizing(true);
  // }, []);

  // const stopResizing = useCallback(() => {
  //   setIsResizing(false);
  // }, []);

  // const resize = useCallback(
  //   (mouseMoveEvent) => {
  //     if (isResizing) {
  //       setSidebarWidth(
  //         sidebarRef.current.getBoundingClientRect().right -
  //           mouseMoveEvent.clientX
  //       );
  //     }
  //   },
  //   [isResizing]
  // );

  // useEffect(() => {
  //   window.addEventListener("mousemove", resize);
  //   window.addEventListener("mouseup", stopResizing);
  //   return () => {
  //     window.removeEventListener("mousemove", resize);
  //     window.removeEventListener("mouseup", stopResizing);
  //   };
  // }, [resize, stopResizing]);

  useEffect(() => {
    if (selectedTreeNode && isMobile) {
      setSidebarOpened(true);
    }
  }, [selectedTreeNode]);

  return (
    <Box className="main-div" sx={{ height: window.innerHeight }}>
      {/* <OnBoardingFlow setSidebarOpened={setSidebarOpened} /> */}
      <SideBar
        setIsKPIView={setIsKPIView}
        setShowManageEvent={setShowManageEvent}
      />
      <Box
        className="left-panel"
        sx={{ marginRight: sidebarOpened ? "20%" : "0" }}
      >
        {!showManageEvent && (
          <Box className="header">
            <Header showManageEvent={showManageEvent} isKPIView={isKPIView} />
          </Box>
        )}
        <Box className="dashboard">
          {showManageEvent ? (
            <ManageEvent
              setShowManageEvent={setShowManageEvent}
              showManageEvent={showManageEvent}
            />
          ) : (
            <Layout
              setShowManageEvent={setShowManageEvent}
              setSidebarOpened={setSidebarOpened}
              isKPIView={isKPIView}
            />
          )}
        </Box>
      </Box>
      <Box
        ref={sidebarRef}
        className="right-panel"
        style={{
          width: "20%",
          position: "fixed",
          left: sidebarOpened ? "80%" : "100vw",
        }}
        onMouseDown={(e) => e.preventDefault()}
      >
        {/* {<div className="app-sidebar-resizer" onMouseDown={startResizing} />} */}
        <Plans setSidebarOpened={setSidebarOpened} />
      </Box>
      {/* {isMobile && (
        <Button
          onClick={() => setSidebarOpened(true)}
          sx={{
            position: "fixed",
            width: "140px",
            bottom: 0,
            right: "calc(50vw - 65px)",
            backgroundColor: "white",
            padding: "6px 4px",
            color: "black",
            textTransform: "none",
            fontFamily: "ClashDisplay-Regular",
            margin: "20px 0px 30px 0px",
            boxShadow: "3px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
          variant="contained"
        >
          <img
            src={treeLogo}
            alt="Logo"
            width="19.2"
            height="19.2"
            style={{
              marginRight: 1,
            }}
          />
          Events History
        </Button>
      )} */}
      <DeleteConfirmationDialog setSidebarOpened={setSidebarOpened} />
    </Box>
  );
}
