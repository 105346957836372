import { Box } from "@mui/material";
import { Sidebar } from "../components/EventGuide/Sidebar";
import { useState, useEffect } from "react";
import { ContextStep } from "../components/EventGuide/Steps/ContextStep";
import { TreeStep } from "../components/EventGuide/Steps/TreeStep";
import { KPIsStep } from "../components/EventGuide/Steps/KPIsStep";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLastCompletedStep,
  setContextStepInfo,
  setTreeStepInfo,
  setKPIs,
  setHistory,
} from "../reducers/treeMapSlice";

const CreateEvent = () => {
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const [treeInfoChanged, setTreeInfoChanged] = useState(false);
  const [importantNotesChanged, setImportantNotesChanged] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const renderStep = () => {
    switch (currentStepNumber) {
      case 1:
        return (
          <ContextStep
            setTreeInfoChanged={setTreeInfoChanged}
            setCurrentStepNumber={setCurrentStepNumber}
          />
        );
      case 2:
        return (
          <TreeStep
            importantNotesChanged={importantNotesChanged}
            setImportantNotesChanged={setImportantNotesChanged}
            setTreeInfoChanged={setTreeInfoChanged}
            treeInfoChanged={treeInfoChanged}
            setCurrentStepNumber={setCurrentStepNumber}
          />
        );
      case 3:
        return (
          <KPIsStep
            importantNotesChanged={importantNotesChanged}
            setImportantNotesChanged={setImportantNotesChanged}
            treeInfoChanged={treeInfoChanged}
            setCurrentStepNumber={setCurrentStepNumber}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    if (location.state?.from === "header") {
      dispatch(setLastCompletedStep(0));
      dispatch(setHistory([]));
      setCurrentStepNumber(1);
      dispatch(setContextStepInfo(null));
      dispatch(setTreeStepInfo(null));
      dispatch(setKPIs(null));
    }
  }, [location]);
  return (
    <Box className="main-div" sx={{ height: window.innerHeight }}>
      <Sidebar
        currentStepNumber={currentStepNumber}
        setTreeInfoChanged={setTreeInfoChanged}
        setCurrentStepNumber={setCurrentStepNumber}
      />
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          backgroundColor: "#f3f3f3",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {renderStep()}
      </Box>
    </Box>
  );
};

export default CreateEvent;
