import axios from "axios";
import store from "../reducers/store";
import { reset } from "../reducers/treeMapSlice";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL_DEV}/v1`;

const commonHeaders = () => ({ Authorization: `Bearer ${localStorage.getItem("accessToken")}`, "Content-Type": "application/json", });

const handleError = (error) => {
  if (error?.response?.status === 401 || error?.response?.data?.message === "jwt expired") {
    signOut()
  }
}

export const generateStripeURL = async (token) => {
  return await axios.post(`${apiUrl}/stripe/checkout/sessions`, {
    headers: commonHeaders(),
  })
}

export const createStripeSession = async (priceId) => {
  return await axios.post(`${apiUrl}/stripe/checkout/sessions`, { priceId }, {
    headers: commonHeaders(),
  })
}

export const createStripeFreeTrial = async (priceId) => {
  return await axios.post(`${apiUrl}/stripe/freetrial`, { priceId }, {
    headers: commonHeaders()
  })
}

export const createStripePayment = async () => {
  return await axios.post(`${apiUrl}/stripe/create_payment-intents`, { amount: 150 }, {
    headers: commonHeaders(),
  })
}

export const getPlansList = async () => {
  try {
    const res = await axios.get(`${apiUrl}/stripe/plans`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const signOut = async () => {
  store.dispatch(reset());
  localStorage.removeItem("accessToken");
  if (window.location.pathname !== "/signin") {
    window.location.href = "/signin";
  }
}

export const sendPasswordResetLink = async ({ email }) => {
  try {
    const res = await axios.post(`${apiUrl}/auth/forget-password`, { email })
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const resetPassword = async ({ password, comfirm_password }) => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token")

    const res = await axios.post(`${apiUrl}/auth/reset-password?token=${token}`, { password, comfirm_password })
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const getUserData = async (userIdToken) => {
  try {
    const res = await axios.get(`${apiUrl}/users/profile`, {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const createEvent = async (requestBody) => {
  try {
    const res = await axios.post(`${apiUrl}/events`, requestBody, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const editEvent = async ({ key, requestBody }) => {
  try {
    const res = await axios.put(`${apiUrl}/events/${key}`, requestBody, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const getEvent = async (key) => {
  try {
    const res = await axios.get(`${apiUrl}/events/${key}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const generateNewBranch = async ({ key, context }) => {
  try {
    const res = await axios.post(`${apiUrl}/events/${key}/branches`, { context }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const getKPIs = async (eventId) => {
  try {
    const res = await axios.get(`${apiUrl}/events/${eventId}/kpis`, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
    return error;
  }
}

export const deleteSingleKPI = async (eventId, kpiId) => {
  try {
    const res = await axios.delete(`${apiUrl}/events/${eventId}/kpis/${kpiId}`, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
    return error;
  }
}

export const editSingleKPIParm = async ({ eventId, kpiId, name, target, description, formula, pitfall, status, current }) => {
  try {
    const res = await axios.put(`${apiUrl}/events/${eventId}/kpis/${kpiId}`, { name, target, description, formula, pitfall, status, current }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
    return error;
  }
}

export const createKPIs = async (eventId) => {
  try {
    const res = await axios.post(`${apiUrl}/events/${eventId}/kpis`, {}, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
    return error;
  }
}

export const regenerateKPIs = async (eventId) => {
  try {
    const res = await axios.put(`${apiUrl}/events/${eventId}/kpis`, {}, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const getTreeHistory = async ({ currentPage }) => {
  try {
    const res = await axios.get(`${apiUrl}/events?page=${currentPage}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const deleteEvent = async ({ deleteEventId }) => {
  try {
    const res = await axios.delete(`${apiUrl}/events/${deleteEventId}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const udpateEventTitle = async ({ treeHistoryId, title, employee_name }) => {
  try {
    const res = await axios.put(`${apiUrl}/events/${treeHistoryId}`, { title, employee_name }, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const generatePlan = async ({ key, node_name }) => {
  try {
    const res = await axios.post(`${apiUrl}/events/${key}/plans`, { node_name }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
    return error;
  }
}

export const getPlan = async ({ key, nodeName }) => {
  try {
    const res = await axios.get(`${apiUrl}/events/${key}/plans?node_name=${nodeName}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const updatePlan = async ({ key, node_name, context }) => {
  try {
    const res = await axios.patch(`${apiUrl}/events/${key}/plans`, { node_name, context }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const removePlanRecord = async ({ key, node_name }) => {
  try {
    const res = await axios.delete(`${apiUrl}/events/${key}/plans/?node_name=${node_name}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const gotoCustomerPortal = async () => {
  try {
    const res = await axios.post(`${apiUrl}/stripe/billing_portal`, {}, {
      headers: commonHeaders(),
    })
    return res.data;
  } catch (error) {
    handleError(error);
  }
}

export const rateAndNote = async ({ node_name, eventId, note, rate }) => {
  try {
    const res = await axios.post(`${apiUrl}/events/${eventId}/nodes`, { node_name, note/* , rate */ }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const updateNodePriority = async ({ node_name, eventId }) => {
  try {
    const res = await axios.post(`${apiUrl}/events/${eventId}/important`, { node_name }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const deleteBranch = async (eventId, node_name) => {
  try {
    const res = await axios.delete(`${apiUrl}/events/${eventId}/branches`, {
      headers: commonHeaders(),
      data: { node_name }
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const createAssessmentUrl = async (eventID, label) => {
  try {
    const res = await axios.put(`${apiUrl}/events/${eventID}/label`, { label }, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const deleteAssessmentUrl = async (eventID, label) => {
  try {
    const res = await axios.delete(`${apiUrl}/events/${eventID}/assessments/delete-by-label/${label}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const createAssessment = async ({ label, node_name, eventId, rate }) => {
  try {
    const res = await axios.post(`${apiUrl}/events/${eventId}/assessments`, { label, node_name, rate })
    return res;
  }
  catch (error) {
    console.log(error);
  }
}

export const getAllAssessments = async ({ eventID, label }) => {
  try {
    const res = await axios.get(`${apiUrl}/events/${eventID}/assessments?label=${label}`)
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const editAssessment = async ({ eventID, nodeRateId, rate, label }) => {
  try {
    const res = await axios.patch(`${apiUrl}/events/${eventID}/assessments/${nodeRateId}?label=${label}`, { rate })
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const deleteAssessment = async ({ eventID, nodeRateId }) => {
  try {
    const res = await axios.delete(`${apiUrl}/events/${eventID}/assessments/${nodeRateId}`)
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const getAsanaWorkspacesById = async ({ eventID, asanaId, redirectUrl }) => {
  try {
    const res = await axios.get(`${apiUrl}/events/${eventID}/asana/workspaces?code=${asanaId}&tokenUrl=${redirectUrl}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const exportEventToAsana = async ({ eventID, workspaceId, teamId }) => {
  try {
    const res = await axios.post(`${apiUrl}/events/${eventID}/asana/export`, { workspaceId, teamId }, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    console.log(error);
  }
}