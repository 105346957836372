import React, { useEffect } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createKPIs, regenerateKPIs } from "../../../reducers/treeMapSlice";
import { ReactComponent as RightIcon } from "../../../assets/images/icons/downIcon.svg";
import Loader from "../../Loader/Loader";
import { ReactComponent as RefreshIcon } from "../../../assets/images/icons/refresh.svg";
import { useNavigate } from "react-router-dom";

const styles = {
  headerTxt: {
    fontSize: "28px",
    fontFamily: "ClashDisplay-Medium",
  },
  headerTxtDesc: {
    marginTop: "10px",
    marginBottom: "20px",
    fontSize: "16px",
    fontFamily: "ClashDisplay-Regular",
    color: "#666666",
  },
  container: {
    width: "50%",
    height: "70%",
    overflowY: "auto",
    margin: "0 auto",
    marginTop: "10%",
    padding: "30px",
    borderRadius: "5px",
    border: "0.5px solid #c1cdd5",
    boxSizing: "border-box",
    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
    backgroundColor: "white",
  },
  footerBTN: {
    width: "180px",
    backgroundColor: "#1D77B7",
    height: "44px",
    color: "white",
    textTransform: "none",
    borderRadius: 2,
    fontSize: 15,
    fontFamily: "ClashDisplay-Medium",
  },
  kpiCard: {
    mb: "20px",
    border: "1px solid #e0e0e0",
    backgroundColor: "#f9f9f9",
    p: "15px",
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  regenrateIcon: {
    height: 40,
    width: 40,
    backgroundColor: "#ececec",
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};
export const KPIsStep = ({
  setImportantNotesChanged,
  setCurrentStepNumber,
  importantNotesChanged,
}) => {
  const selectedEventId = useSelector((state) => state.treeMap.selectedTreeId);
  const kpis = useSelector((state) => state.treeMap.KPIs);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (importantNotesChanged && !kpis) {
      dispatch(createKPIs(selectedEventId));
      setImportantNotesChanged(false);
    } else if (importantNotesChanged) {
      dispatch(regenerateKPIs(selectedEventId));
      setImportantNotesChanged(false);
    }
  }, [importantNotesChanged]);

  const KPICard = ({ ...kpi }) => {
    return (
      <Box sx={styles.kpiCard}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          {kpi.name}
        </Typography>
        {["Formula", "Description", "Target", "Pitfall"].map((label) => (
          <Typography key={label} sx={{ fontSize: "14px", color: "#5b5b5b" }}>
            <strong>{label}:</strong> {kpi[label.toLowerCase()]}
          </Typography>
        ))}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        p: "20px",
        boxSizing: "border-box",
        overflowY: "auto",
      }}
    >
      <Box sx={styles.container}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.headerTxt}>Judo KPIs</Typography>
          <Tooltip title="Regenerate KPIs">
            <Box
              sx={styles.regenrateIcon}
              onClick={() => dispatch(regenerateKPIs(selectedEventId))}
            >
              <RefreshIcon />
            </Box>
          </Tooltip>
        </Box>
        <Typography sx={styles.headerTxtDesc}>
          Review your Judo KPIs based on your inversion analysis
        </Typography>
        {kpis ? (
          kpis.kpisData.map((kpi) => <KPICard key={kpi._id} {...kpi} />)
        ) : (
          <Box
            sx={{
              mt: "50%",
              position: "relative",
            }}
          >
            <Loader text="Generating KPIs..." />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "20px 25%",
          flexShrink: 0,
        }}
      >
        <Button
          variant="contained"
          onClick={() => setCurrentStepNumber(2)}
          sx={{
            ...styles.footerBTN,
            "&:hover": {
              backgroundColor: "#1D77B7",
              boxShadow: "none",
            },
          }}
          startIcon={
            <RightIcon
              style={{
                width: 15,
                height: 15,
                transform: "rotate(90deg)",
                fill: "white",
              }}
            />
          }
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            navigate("/");
          }}
          sx={{
            ...styles.footerBTN,
            "&:hover": {
              backgroundColor: "#1D77B7",
              boxShadow: "none",
            },
          }}
        >
          Finish
        </Button>
      </Box>
    </Box>
  );
};
